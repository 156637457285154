<template>
  <div class="dashboard-container">
    <div class="topbar">
      <div class="logo-section">
        <img src="@/assets/icons/logo.png" alt="Logo" class="logo" />
        <span>TochaHub</span>
      </div>
      <div class="user-profile">
        <img
          src="@/assets/icons/cheff.png"
          alt="User Icon"
          class="user-icon"
          @click="toggleUserMenu"
        />
        <div v-if="showUserMenu" class="user-menu">
          <p>{{ userName }}</p>
          <a :href="whatsappLink" target="_blank">Ajuda?</a>
          <button @click="logout">Sair</button>
        </div>
      </div>
    </div>

    <div class="content-container">
      <!-- Sidebar -->
      <div :class="['sidebar', { collapsed: isSidebarCollapsed }]">
        <div
          class="menu-item"
          @click="
            toggleMenu('inicio');
            showContent('inicio');
          "
          :class="{ active: selectedContent === 'inicio' }"
        >
          <span v-if="!isSidebarCollapsed">🏠 Início</span>
          <i v-else>🏠</i>
        </div>

        <div
          class="menu-item"
          @click="toggleMenu('cardapio')"
          :class="{ active: activeMenu === 'cardapio' }"
        >
          <span v-if="!isSidebarCollapsed">🍽 Cardápio</span>
          <i v-else>🍽</i>
          <span v-if="activeMenu === 'cardapio' && !isSidebarCollapsed">▼</span>
        </div>

        <div
          v-if="activeMenu === 'cardapio' && !isSidebarCollapsed"
          class="submenu"
        >
          <div
            @click="showContent('categorias')"
            :class="{ active: selectedContent === 'categorias' }"
          >
            🔖 Categorias
          </div>
          <div
            @click="showContent('produtos')"
            :class="{ active: selectedContent === 'produtos' }"
          >
            📦 Produtos
          </div>
          <div
              @click="showContent('exibição')"
              :class="{ active: selectedContent === 'exibição' }"
          >
            ⚙️ Exibição
          </div>
          <div
            @click="showContent('links')"
            :class="{ active: selectedContent === 'links' }"
          >
            🔗 Links
          </div>
        </div>

        <!-- Botão de Retratação -->
        <div class="sidebar-footer">
          <button class="toggle-sidebar" @click="toggleSidebar">
            <span>{{ isSidebarCollapsed ? "" : "Fechar menu" }}</span>
            <i>≡</i>
          </button>
        </div>
      </div>

      <!-- Conteúdo Principal -->
      <div class="content">
        <div v-if="selectedContent === 'inicio'">
          <h2>Início</h2>
          <p>Bem-vindo ao painel principal.</p>
        </div>
        <div v-if="selectedContent === 'categorias'">
          <CategoryPage />
        </div>
        <div v-if="selectedContent === 'produtos'">
          <ProductsPage />
        </div>
        <div v-if="selectedContent === 'exibição'">
          <ConfigureDisplayMenuPage />
        </div>
        <div v-if="selectedContent === 'links'">
          <LinkPage />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryPage from "@/views/CategoryPage.vue";
import ProductsPage from "@/views/ProductsPage.vue";
import ConfigureDisplayMenuPage from "@/views/ConfigureDisplayMenuPage.vue";
import LinkPage from "@/views/LinkPage.vue";
import authMixin from "@/mixins/authMixin";

export default {
  mixins: [authMixin],
  data() {
    return {
      showUserMenu: false,
      activeMenu: null,
      selectedContent: "inicio",
      userName: "Usuário",
      whatsappLink: "https://wa.me/xxxxxxxxxx",
      isSidebarCollapsed: false,
    };
  },
  methods: {
    toggleUserMenu() {
      this.showUserMenu = !this.showUserMenu;
    },
    toggleMenu(menu) {
      if (this.isSidebarCollapsed) {
        this.isSidebarCollapsed = false;
      }
      if (this.activeMenu === menu) {
        this.activeMenu = null;
        this.selectedContent = null;
      } else {
        this.activeMenu = menu;
        if (menu === "inicio") {
          this.selectedContent = "inicio";
        } else {
          this.selectedContent = null;
        }
      }
    },
    showContent(content) {
      this.selectedContent = content;
      if (["categorias", "produtos", "exibição", "links"].includes(content)) {
        this.activeMenu = "cardapio";
      } else {
        this.activeMenu = null;
      }
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/");
    },
    toggleSidebar() {
      this.isSidebarCollapsed = !this.isSidebarCollapsed;
    },
  },
  components: {
    CategoryPage,
    ProductsPage,
    ConfigureDisplayMenuPage,
    LinkPage,
  },
};
</script>

<style scoped>
.dashboard-container {
  display: flex;
  height: 100vh;
  flex-direction: column;
}
.topbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
}
.logo-section {
  display: flex;
  align-items: center;
}
.logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.user-profile {
  position: relative;
}
.user-icon {
  width: 50px;
  height: 50px;
  margin-right: 55px;
  cursor: pointer;
}
.user-menu {
  position: absolute;
  top: 50px;
  right: 0;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding-right: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.user-menu a,
.user-menu button {
  margin-top: 5px;
  text-align: left;
  width: 100%;
  border: none;
  background: none;
  cursor: pointer;
}
.content-container {
  display: flex;
  flex: 1;
  margin-top: 60px;
}
.sidebar {
  width: 200px;
  background-color: #fff;
  padding: 0; /* Removido o padding geral */
  position: fixed;
  top: 60px;
  left: 0;
  height: calc(100vh - 60px);
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: width 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sidebar.collapsed {
  width: 60px;
}
.menu-item,
.submenu div {
  cursor: pointer;
  padding: 10px 0; /* Removido o padding lateral */
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
  color: #007bff;
  font-family: "Arial", sans-serif;
  width: 100%; /* Ocupar toda a largura */
  text-indent: 10px; /* Indentar o texto para espaçamento */
}
.menu-item:hover,
.submenu div:hover {
  background-color: rgba(194, 151, 12, 0.1);
}
.menu-item.active,
.submenu div.active {
  background-color: rgba(10, 196, 209, 0.048);
  font-weight: bold;
}
.content {
  flex: 1;
  margin-left: 200px;
  padding: 20px;
  overflow-y: auto;
  color: #007bff;
  font-family: "Arial", sans-serif;
}
.sidebar.collapsed + .content {
  margin-left: 60px;
}
.sidebar-footer {
  margin-top: auto;
  text-align: center;
  padding: 10px 0;
  width: 100%;
}
.toggle-sidebar {
  background: none;
  border: none;
  color: #007bff;
  font-family: "Arial", sans-serif;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  text-indent: 10px;
}
</style>
