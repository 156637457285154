import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  watch: {
    isAuthenticated(value) {
      if (!value) {
        this.$router.push("/");
      }
    },
  },
  mounted() {
    if (!this.isAuthenticated) {
      this.$router.push("/");
    }
  },
};
