<template>
  <div class="products-page">
    <div class="header">
      <input v-model="search" placeholder="Pesquisar" @input="onSearch" />
      <button @click="openAddProductModal">+ Novo produto</button>
    </div>

    <table>
      <thead>
        <tr>
          <th>Thumbnail</th>
          <th>Descrição</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="product in products" :key="product.id">
          <td>
            <img
              :src="product.mediasUrl[0].thumb_url || product.mediasUrl[0].url"
              alt="Thumbnail"
              class="product-thumbnail"
            />
          </td>
          <td>{{ product.description }}</td>
          <td>
            <button @click="verifyDelete(product)">Remover</button>
          </td>
        </tr>
      </tbody>
    </table>

    <AddProductModal
      v-if="isAddProductModalOpen"
      @close="isAddProductModalOpen = false"
      @productAdded="onProductAdded"
    />

    <RemoveItemModal
      :isVisible="showRemoveModal"
      title="Confirmação de Exclusão"
      :message="`Você realmente deseja excluir o produto: '${this.productSelected.description}'?`"
      @close="hideModalDelete"
      @confirm="deleteProduct(this.productSelected.id)"
    />
  </div>
</template>

<script>
import axios from "axios";
import AddProductModal from "@/components/AddProductModal.vue";
import RemoveItemModal from "../components/RemoveItemModal.vue";
import store from "../store";
const baseUrl = require("../constants/constants");
const getProductsApi = baseUrl + "/products";
const removeProductApi = baseUrl + "/product";
import authMixin from "@/mixins/authMixin";

export default {
  mixins: [authMixin],
  data() {
    return {
      products: [],
      page: 1,
      limit: 20,
      search: "",
      isAddProductModalOpen: false,
      showRemoveModal: false,
      searchTimeout: null,
      isLoading: false,
      hasMore: true,
      productSelected: {
        id: "",
        description: "",
      },
    };
  },
  methods: {
    async fetchProducts(append = false) {
      if (this.isLoading) return;
      if (!this.hasMore && append) return;
      this.isLoading = true;
      try {
        const params = {
          limit: this.limit,
          page: this.page,
        };
        if (this.search) {
          params.description = this.search;
        }
        const response = await axios.get(getProductsApi, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + store.getters.getToken,
          },
          params,
        });
        const newProducts = response.data.data;
        if (append) {
          this.products = this.products.concat(newProducts);
        } else {
          this.products = newProducts;
        }
        if (newProducts.length < this.limit) {
          this.hasMore = false;
        } else {
          this.hasMore = true;
        }

        this.$nextTick(() => {
          this.checkIfNeedMoreProducts();
        });
      } catch (error) {
        console.error("Erro ao buscar produtos:", error);
      } finally {
        this.isLoading = false;
      }
    },
    checkIfNeedMoreProducts() {
      const container = this.$el;
      if (container.scrollHeight <= window.innerHeight && this.hasMore) {
        this.page++;
        this.fetchProducts(true);
      }
    },
    onSearch() {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.page = 1;
        this.products = [];
        this.hasMore = true;
        this.fetchProducts();
      }, 300);
    },
    openAddProductModal() {
      this.isAddProductModalOpen = true;
    },
    onProductAdded() {
      this.isAddProductModalOpen = false;
      this.page = 1;
      this.fetchProducts();
    },
    verifyDelete(product) {
      this.productSelected = {
        id: product.id,
        description: product.description,
      };
      this.showRemoveModal = true;
    },
    hideModalDelete() {
      this.productSelected = {
        id: "",
        description: "",
      };
      this.showRemoveModal = false;
    },
    async deleteProduct(productId) {
      try {
        await axios.delete(`${removeProductApi}/${productId}`, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + store.getters.getToken,
          },
        });
        this.page = 1;
        this.products = [];
        this.hasMore = true;
        await this.fetchProducts();
      } catch (error) {
        console.error("Erro ao deletar produto:", error);
      }
    },
    handleScroll() {
      if (this.isLoading || !this.hasMore) return;

      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop || 0;
      const windowHeight = window.innerHeight;
      const totalHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;

      if (scrollTop + windowHeight >= totalHeight - 250) {
        this.page++;
        this.fetchProducts(true);
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.fetchProducts();
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  components: {
    AddProductModal,
    RemoveItemModal,
  },
};
</script>

<style scoped>
.product-thumbnail {
  width: 120px;
  height: 120px;
}
</style>
