<template>
  <div class="modal" v-if="isVisible">
    <div class="modal-container">
      <div class="modal-header">
        <h3>{{ title }}</h3>
      </div>
      <div class="modal-body">
        <p>{{ message }}</p>
      </div>
      <div class="modal-footer">
        <button @click="confirm" class="btn btn-danger">Confirmar</button>
        <button @click="closeModal" class="btn btn-secondary">Cancelar</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: "Confirmação",
    },
    message: {
      type: String,
      default: "Você tem certeza que deseja excluir este item?",
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    confirm() {
      this.$emit("confirm");
      this.closeModal();
    },
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
}
.modal-container {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}
.modal-header,
.modal-footer {
  padding: 10px;
}
.close {
  cursor: pointer;
}
.btn {
  margin-right: 10px;
}
</style>
