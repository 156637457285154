<template>
  <div class="container">
    <h1>Configurar exibição do menu</h1>
    <div class="menu-name-container">
      <label for="menu-name">Nome do Cardápio:</label>
      <input
        id="menu-name"
        type="text"
        v-model="menuName"
        @blur="updateMenuName"
        placeholder="Digite o nome do cardápio"
      />
    </div>
    <div class="modal" v-if="showModal">
      <div class="modal-content">
        <h2>Confirmação</h2>
        <p>Alterar o nome do cardápio para "{{ menuName }}"?</p>
        <div class="modal-actions">
          <button @click="confirmUpdateMenuName">Confirmar</button>
          <button @click="cancelUpdateMenuName">Cancelar</button>
        </div>
      </div>
    </div>
    <PaginatedList
      :items="categories"
      :orientation="'horizontal'"
      customClass="categories-list"
      @onItemClick="onCategorySelect"
      @nextPage="getNextCategoriesPage"
      @itemsSwapped="updatePositionCategories"
    />
    <PaginatedList
      :items="products"
      :orientation="'vertical'"
      @nextPage="getNextProductsPage"
      @itemsSwapped="updatePositionProducts"
    />
  </div>
</template>
<script>
import axios from "axios";
import store from "../store";
import PaginatedList from "@/components/PaginatedList.vue";
import authMixin from "@/mixins/authMixin";

const baseUrl = require("../constants/constants");
const getProductsApi = baseUrl + "/products";
const getCategoriesApi = baseUrl + "/categories";
const getMenuApi = baseUrl + "/menu";
const updatePositionProductsApi = baseUrl + "/products/change_position";
const updatePositionCategoriesApi = baseUrl + "/categories/change_position";

export default {
  mixins: [authMixin],
  data() {
    return {
      products: [],
      categories: [],
      productsPage: 1,
      categoriesPage: 1,
      limit: 5,
      isProductsLoading: false,
      isCategoriesLoading: false,
      hasMoreProducts: true,
      hasMoreCategories: true,
      selectedCategoryId: null,
      menuName: "",
      menuId: null,
      pendingMenuName: "",
      showModal: false,
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + store.getters.getToken,
      },
    };
  },
  methods: {
    async fetchProducts(append = false) {
      if (this.isProductsLoading) return;
      if (!this.hasMoreProducts && append) return;
      this.isProductsLoading = true;
      try {
        const params = {
          limit: this.limit,
          page: this.productsPage,
          category_id: this.selectedCategoryId,
          sorted_by_position: true,
        };
        const response = await axios.get(getProductsApi, {
          headers: this.headers,
          params,
        });
        const newProducts = response.data.data.map((product) => ({
          id: product.id,
          description: product.description,
          thumbUrl:
            product.mediasUrl[0]?.thumb_url || product.mediasUrl[0]?.url,
        }));
        if (append) {
          this.products = this.products.concat(newProducts);
        } else {
          this.products = newProducts;
        }
        this.hasMoreProducts = response.data.pagination.next != null;
        if (this.hasMoreProducts) {
          this.$nextTick(() => {
            this.checkIfNeedMoreProducts();
          });
        }
      } catch (error) {
        console.error("Erro ao buscar produtos:", error);
      } finally {
        this.isProductsLoading = false;
      }
    },
    async fetchCategories(append = false) {
      if (this.isCategoriesLoading) return;
      if (!this.hasMoreCategories && append) return;
      this.isCategoriesLoading = true;
      try {
        const params = {
          limit: this.limit,
          page: this.categoriesPage,
          sorted_by_position: true,
        };
        const response = await axios.get(getCategoriesApi, {
          headers: this.headers,
          params,
        });
        const newCategories = response.data.data.map((category) => ({
          id: category.id,
          description: category.name,
          thumbUrl: category.thumbUrl,
        }));
        if (this.selectedCategoryId == null) {
          this.selectedCategoryId = newCategories[0].id;
        }
        if (append) {
          this.categories = this.categories.concat(newCategories);
        } else {
          this.categories = newCategories;
        }
        this.hasMoreCategories = response.data.pagination.next != null;
        if (this.hasMoreCategories) {
          this.$nextTick(() => {
            this.checkIfNeedMoreCategories();
          });
        }
      } catch (error) {
        console.error("Erro ao buscar categorias:", error);
      } finally {
        this.isCategoriesLoading = false;
      }
    },
    async updatePositionProducts(products) {
      console.log(products);
      try {
        await axios.put(updatePositionProductsApi, products, {
          headers: this.headers,
        });
      } catch (error) {
        alert("Ocorreu um erro ao tentar alterar a posição dos produtos");
      }
    },
    async updatePositionCategories(categories) {
      try {
        await axios.put(updatePositionCategoriesApi, categories, {
          headers: this.headers,
        });
      } catch (error) {
        alert("Ocorreu um erro ao tentar alterar a posição das categorias.");
      }
    },
    async fetchMenuDetails() {
      try {
        const response = await axios.get(getMenuApi, {
          headers: this.headers,
          params: { limit: 1 },
        });
        const menu_response = response.data.data[0];
        this.menuName = menu_response.name;
        this.pendingMenuName = this.menuName;
        this.menuId = menu_response.id;
      } catch (error) {
        console.error("Erro ao buscar detalhes do menu:", error);
      }
    },
    async updateMenuName() {
      if (!this.menuName.trim()) {
        return;
      }
      if (this.menuName === this.pendingMenuName) {
        return;
      }
      this.showModal = true;
    },
    async confirmUpdateMenuName() {
      this.showModal = false;
      try {
        await axios.put(
          getMenuApi,
          { name: this.menuName, menuId: this.menuId },
          { headers: this.headers }
        );
        this.pendingMenuName = this.menuName;
        alert("Nome do cardápio atualizado com sucesso!");
      } catch (error) {
        console.error("Erro ao atualizar o nome do menu:", error);
        alert(
          "Não foi possível atualizar o nome do cardápio. Tente novamente."
        );
      }
    },
    cancelUpdateMenuName() {
      this.showModal = false;
      this.menuName = this.pendingMenuName;
    },
    getNextProductsPage() {
      this.productsPage++;
      this.fetchProducts(true);
    },
    checkIfNeedMoreProducts() {
      const container = this.$el;
      if (container.scrollHeight <= window.innerHeight) {
        this.getNextProductsPage();
      }
    },
    getNextCategoriesPage() {
      this.categoriesPage++;
      this.fetchCategories(true);
    },
    checkIfNeedMoreCategories() {
      const container = this.$el;
      if (container.scrollWidth <= window.innerWidth) {
        this.getNextCategoriesPage();
      }
    },
    onCategorySelect(item) {
      this.selectedCategoryId = item.id;
      this.productsPage = 1;
      this.products = [];
      this.fetchProducts();
    },
  },
  mounted: async function () {
    await this.fetchCategories();
    await this.fetchProducts();
    await this.fetchMenuDetails();
  },
  components: {
    PaginatedList,
  },
};
</script>
<style scoped>
.menu-name-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.menu-name-container label {
  margin-right: 0.5rem;
  font-weight: bold;
}

.menu-name-container input {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background: #fff;
  padding: 1.5rem;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.modal-actions button {
  margin: 0 0.5rem;
}
</style>

