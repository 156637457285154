<template>
  <div ref="scrollContainer" @scroll="handleScroll" :class="['list-container', orientation, customClass]">
    <CustomDraggable v-model="paginatedItems"
                     :orientation="orientation"
                     @itemsSwapped="itemsSwapped">
      <template #default="{ item }">
        <div class="item" @click="selectItem(item)">
          <img :src="item.thumbUrl" alt="Imagem do item" class="item-image" width="120px" height="120px"/>
          <div class="item-text">
            {{ item.description }}
          </div>
        </div>
      </template>
    </CustomDraggable>
    <div v-if="loading" class="loading">Carregando mais itens...</div>
  </div>
</template>

<script>
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';
import CustomDraggable from './CustomDraggable.vue';

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    itemsPerPage: {
      type: Number,
      default: 5
    },
    orientation: {
      type: String,
      default: 'vertical',
      validator: value => ['vertical', 'horizontal'].includes(value)
    },
    customClass: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const paginatedItems = ref([]);

    watch(
        () => props.items,
        (newItems) => {
          paginatedItems.value = [...newItems];
        },
        { immediate: true }
    );

    const loadItems = () => {
      const start = paginatedItems.value.length;
      const newItems = props.items.slice(start, start + props.itemsPerPage);
      paginatedItems.value.push(...newItems);
    };

    const handleScroll = () => {
      if (props.orientation === 'vertical') {
        const scrollTop = window.scrollY || document.documentElement.scrollTop || 0;
        const windowHeight = window.innerHeight;
        const totalHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        if (scrollTop + windowHeight >= totalHeight - 250) {
          emit("nextPage");
        }
      } else {
        const scrollLeft = window.scrollX || document.documentElement.scrollLeft || 0;
        const windowWidth = window.innerWidth;
        const totalWidth = document.documentElement.scrollWidth || document.body.scrollWidth;
        if (scrollLeft + windowWidth >= totalWidth - 250) {
          emit("nextPage");
        }
      }
    };

    function itemsSwapped(items) {
      emit("itemsSwapped", items);
    }

    onMounted(() => {
      window.scrollTo(0, 0);
      loadItems();
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleScroll);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    });

    return { paginatedItems, loading, handleScroll, itemsSwapped };
  },
  methods: {
    selectItem(item) {
      this.$emit('onItemClick', item);
    },
  },
  components: {
    CustomDraggable,
  },
};
</script>

<style scoped>
.list-container {
  display: flex;
  overflow-x: auto;
}

.list-container.vertical {
  flex-direction: column;
}

.list-container.horizontal {
  flex-direction: row;
}

.item {
  padding: 10px;
  cursor: grab;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.item:last-child {
  margin-right: 0;
}

.item-image {
  margin-right: 10px;
}

.item-text {
  font-size: 16px;
}

.loading {
  text-align: center;
}

.item:active {
  cursor: grabbing;
}

.categories-list .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.categories-list .item-image {
  margin-bottom: 10px;
}

.categories-list .item-text {
  font-size: 16px;
  line-height: 1.2;
  color: blue;
}

</style>
