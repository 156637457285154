<template>
  <img
    src="@/assets/icons/logo.png"
    alt="TochaHub Logo"
    class="w-[120px] mx-auto mb-5 sm:w-[100px]"
  />
  <div
    class="flex justify-center items-center min-h-screen w-full bg-gradient-to-b from-white to-[#ffe5b2] overflow-hidden box-border font-semibold"
  >
    <div
      class="w-full h-[450px] max-w-[360px] p-5 mb-[390px] bg-white rounded-lg shadow-md text-center mt-[20px] sm:max-w-[320px]"
    >
      <div>
        <h2 class="text-3xl mb-3 text-gray-600 mt-[12px] sm:text-3xl">
          TochaHub
        </h2>
      </div>

      <div
        v-if="errorMessage"
        class="p-4 mt-1 text-sm text-red-700 bg-red-100 rounded"
      >
        {{ errorMessage }}
      </div>

      <form @submit.prevent="handleLogin">
        <div class="mb-4 mt-12">
          <h1 class="text-sm text-gray-600 mb-2 sm:mr-[230px]">E-mail</h1>
          <div
            class="flex items-center border border-gray-200 rounded bg-gray-100 overflow-hidden focus-within:border-blue-500"
          >
            <input
              type="text"
              v-model="username"
              id="username"
              placeholder="Digite seu e-mail"
              class="flex-1 border-none bg-gray-100 p-2 text-sm outline-none sm:p-1 h-9"
              required
            />
          </div>
        </div>

        <div class="mb-6">
          <h1 class="text-sm text-gray-600 mb-2 sm:mr-[240px]">Senha</h1>
          <div
            class="flex items-center border border-gray-200 rounded bg-gray-100 overflow-hidden focus-within:border-blue-500"
          >
            <input
              :type="showPassword ? 'text' : 'password'"
              v-model="password"
              id="password"
              placeholder="Digite sua senha"
              class="flex-1 border-none bg-gray-100 p-2 h-9 text-sm outline-none sm:p-1"
              @focus="hasInputBeenClicked = true"
              required
            />
            <button
              v-if="hasInputBeenClicked"
              type="button"
              class="p-2 text-gray-300 hover:text-gray-400 focus:outline-none"
              @click="toggleShowPassword"
            >
              <svg
                v-if="showPassword"
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M10 3C5 3 1.735 6.9 1 10c.735 3.1 4 7 9 7s8.265-3.9 9-7c-.735-3.1-4-7-9-7zm0 12a5 5 0 1 1 0-10 5 5 0 0 1 0 10zm0-3a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                />
              </svg>
              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M10 3c-5 0-8.265 3.9-9 7 .735 3.1 4 7 9 7s8.265-3.9 9-7c-.735-3.1-4-7-9-7zM10 15a5 5 0 0 1-4.78-3.738 6.992 6.992 0 0 0 4.78 1.737 6.992 6.992 0 0 0 4.78-1.737A5 5 0 0 1 10 15z"
                />
              </svg>
            </button>
          </div>
        </div>

        <button
          type="submit"
          class="w-full py-2 bg-[#ff6c37] text-white rounded text-lg cursor-pointer hover:bg-[#d9542b] mb-5 sm:text-base sm:py-1"
        >
          Entrar
        </button>

        <div class="flex justify-between items-center text-1xl mb-8">
          <a
            href="#"
            @click.prevent="redirectToWhatsApp"
            class="text-blue-600 hover:underline ml-auto"
            >Esqueceu sua senha?</a
          >
        </div>
        <p class="text-gray-500 mt-auto sm:mt-[130px]">
          TochaMenu Powered by TochaHub
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "../store";
const baseUrl = require("../constants/constants");

export default {
  data() {
    return {
      username: "",
      isInputFocused: false,
      showPassword: false,
      hasInputBeenClicked: false,
      password: "",
      errorMessage: "",
    };
  },
  created() {
    this.checkToken();
  },
  methods: {
    checkToken() {
      if (store.getters.isAuthenticated) {
        this.$router.push("/dashboard");
      }
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    redirectToWhatsApp() {
      const message = "Olá! Esqueci minha senha";
      const encodedMessage = encodeURIComponent(message);
      window.location.href = `https://wa.me/558591387654?text=${encodedMessage}`;
    },
    async handleLogin() {
      try {
        const apiUrl = `${baseUrl}/login`;

        const response = await axios.post(apiUrl, {
          username: this.username,
          password: this.password,
        });

        if (response.status === 200 && response.data.token) {
          const token = response.data.token;
          this.$store.dispatch("login", token);
          this.$router.push("/dashboard");
        } else {
          this.errorMessage =
            "Erro de autenticação, verifique suas credenciais e tente novamente.";
        }
      } catch (error) {
        console.error("Erro ao autenticar:", error);
        this.errorMessage = "E-mail ou senha incorretos. Tente novamente.";
      }
    },
  },
};
</script>
