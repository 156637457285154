import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from '@/views/LoginPage.vue';
import DashboardPage from '../views/DashboardPage.vue';
import UserRegisterPage from '../views/UserRegisterPage.vue';
import store from '../store';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginPage
  },
  {
    path: '/dashboard',
    name: 'DashboardPage',
    component: DashboardPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/register',
    name: 'UserRegisterPage',
    component: UserRegisterPage,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (store.getters.isAuthenticated) {
      next();
    } else {
      next('/');
    }
  } else {
    next();
  }
});

export default router;
