<template>
  <div class="register-container">
    <div class="register-box">
      <img src="@/assets/icons/logo.png" alt="TochaMenu Logo" class="logo" />
      <h2>TochaMenu</h2>
      <h3>{{ headerText }}</h3>

      <form v-if="step === 1" @submit.prevent="handleEmailSubmit">
        <div class="input-group">
          <label for="email">Seu E-mail</label>
          <input
            type="email"
            v-model="email"
            id="email"
            placeholder="Insira seu E-mail"
            required
          />
        </div>
        <button type="submit" class="register-button">Enviar Código</button>
      </form>

      <div v-if="step === 1" class="login-prompt">
        <p>
          Já tem uma conta?
          <router-link to="/" class="login-link">Ir ao login</router-link>
        </p>
      </div>

      <form v-else-if="step === 2" @submit.prevent="handleCodeSubmit">
        <div class="input-group">
          <label for="code">Código de Verificação</label>
          <input
            type="text"
            v-model="code"
            id="code"
            placeholder="Insira o Código Enviado via E-mail"
            required
          />
        </div>
        <button type="submit" class="register-button">Verificar Código</button>
      </form>

      <form v-else-if="step === 3" @submit.prevent="handleRegistration">
        <div class="input-group">
          <label for="phone">Seu Telefone</label>
          <input
            type="text"
            v-model="phone"
            id="phone"
            placeholder="Insira seu Telefone"
            required
          />
        </div>
        <div class="input-group">
          <label for="establishmentName">Nome do Estabelecimento</label>
          <input
            type="text"
            v-model="establishmentName"
            id="establishmentName"
            placeholder="Insira o Nome do Estabelecimento"
            required
          />
        </div>
        <div class="input-group">
          <label for="password">Crie uma Senha</label>
          <div class="password-input-wrapper">
            <input
              :type="showPassword ? 'text' : 'password'"
              v-model="password"
              id="password"
              placeholder="Crie uma Senha"
              required
            />
            <span
              class="toggle-password"
              @click="toggleShowPassword"
              v-if="password.length > 0">
              <img
                :src="
                  showPassword
                    ? require('@/assets/icons/eye-open.png')
                    : require('@/assets/icons/eye-closed.png')
                "
                alt="Toggle Password Visibility"
              />
            </span>
          </div>
        </div>
        <div class="input-group">
          <label for="confirmPassword">Confirme sua Senha</label>
          <div class="password-input-wrapper">
            <input
              :type="showPassword ? 'text' : 'password'"
              v-model="confirmPassword"
              id="confirmPassword"
              placeholder="Confirme sua Senha"
              required
            />
            <span
              class="toggle-password"
              @click="toggleShowPassword"
              v-if="confirmPassword.length > 0">
              <img
                :src="
                  showPassword
                    ? require('@/assets/icons/eye-open.png')
                    : require('@/assets/icons/eye-closed.png')
                "
                alt="Toggle Password Visibility"
              />
            </span>
          </div>
        </div>
        <button type="submit" class="register-button">Concluir Cadastro</button>
      </form>

      <div v-else-if="step === 4" class="success-message">
        <h3>Cadastro Realizado com Sucesso!</h3>
        <p>Você pode agora fazer login.</p>
        <router-link to="/" class="login-link">Ir para Login</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const baseUrl = require("../constants/constants");

export default {
  data() {
    return {
      email: "",
      code: "",
      phone: "",
      establishmentName: "",
      password: "",
      confirmPassword: "", 
      showPassword: false,
      step: 1,
    };
  },
  computed: {
    headerText() {
      switch (this.step) {
        case 1:
          return "Informe seu E-mail";
        case 2:
          return "Verifique o código enviado ao seu E-mail";
        case 3:
          return "Agora complete seu Cadastro";
        case 4:
          return "Cadastro Concluído";
        default:
          return "Cadastro de Usuário";
      }
    },
  },
  methods: {
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    async handleEmailSubmit() {
      try {
        const apiUrl = baseUrl + "/send-code";
        const response = await axios.post(apiUrl, { email: this.email });
        if (response.status === 200) {
          alert("Código de verificação enviado para seu email.");
          this.step = 2;
        }
      } catch (error) {
        console.error("Erro ao enviar código:", error);
        alert(error.response.data.error || "Erro ao enviar código.");
      }
    },
    async handleCodeSubmit() {
      try {
        const apiUrl = baseUrl + "/verify-code";
        const response = await axios.post(apiUrl, {
          email: this.email,
          code: this.code,
        });
        if (response.status === 200) {
          alert("Código verificado com sucesso.");
          this.step = 3;
        }
      } catch (error) {
        console.error("Erro ao verificar código:", error);
        alert(error.response.data.error || "Erro ao verificar código.");
      }
    },
    async handleRegistration() {
      if (this.password !== this.confirmPassword) {
        alert("As senhas não correspondem.");
        return;
      }
      try {
        const apiUrl = baseUrl + "/complete-registration";
        const response = await axios.post(apiUrl, {
          email: this.email,
          password: this.password,
          phone: this.phone,
          establishmentName: this.establishmentName,
        });
        if (response.status === 200) {
          this.step = 4;
        }
      } catch (error) {
        console.error("Erro ao completar registro:", error);
        alert(error.response.data.error || "Erro ao completar registro.");
      }
    },
  },
};
</script>

<style scoped>
.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}
.register-box {
  width: 400px;
  padding: 20px 0;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.logo {
  width: 150px;
  margin: 0 auto;
  display: block;
  border-radius: 10px;
}
h2,
h3 {
  text-align: center;
}
h2 {
  font-size: 24px;
  color: #333;
}
h3 {
  font-size: 24px;
  color: #0099cc;
  margin-bottom: 20px;
}
.input-group {
  margin-bottom: 20px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  text-align: left;
}
label {
  display: block;
  margin-bottom: 5px;
  color: #555;
}
input[type="text"],
input[type="password"],
input[type="email"] {
  width: 100%;
  padding: 10px;
  padding-right: 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.password-input-wrapper {
  position: relative;
}
.toggle-password {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  cursor: pointer;
}
.toggle-password img {
  width: 20px;
  height: 20px;
}
.register-button {
  width: calc(100% - 40px);
  margin: 0 20px;
  padding: 10px;
  background: #0099cc;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}
.register-button:hover {
  background: #007399;
}
.success-message {
  text-align: center;
  padding: 0 20px;
}
.login-link {
  display: inline-block;
  margin-top: 20px;
  color: #0099cc;
  text-decoration: none;
}
.login-link:hover {
  text-decoration: underline;
}

.login-prompt {
  text-align: center;
  margin-top: 10px;
}

.login-prompt p {
  color: #555;
}

.login-link {
  color: #0099cc;
  text-decoration: none;
  font-weight: bold;
}

.login-link:hover {
  text-decoration: underline;
}
</style>
