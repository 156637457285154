<template>
  <div class="draggable-container" :class="orientation">
    <div
        v-for="(item, index) in paginatedItems"
        :key="index"
        class="draggable-item"
        :draggable="true"
        @dragstart="onDragStart(index)"
        @dragover.prevent
        @drop="onDrop"
        :data-index="index">
      <slot :item="item">
        {{ item }}
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomDraggable',
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    orientation: {
      type: String,
      default: 'vertical',
      validator: value => ['vertical', 'horizontal'].includes(value),
    },
  },
  emits: ['update:modelValue', 'end', 'itemsSwapped'],

  data() {
    return {
      draggedItemIndex: null,
      paginatedItems: [...this.modelValue],
    };
  },
  watch: {
    modelValue(newItems) {
      this.paginatedItems = [...newItems];
    },
  },
  methods: {
    onDragStart(index) {
      this.draggedItemIndex = index;
    },

    onDrop(event) {
      const droppedIndex = event.currentTarget.dataset.index;
      if (this.draggedItemIndex !== null && droppedIndex !== undefined) {
        const targetIndex = Number(droppedIndex);
        const draggedItem = this.paginatedItems[this.draggedItemIndex];
        const targetItem = this.paginatedItems[targetIndex];
        this.paginatedItems[this.draggedItemIndex] = targetItem;
        this.paginatedItems[targetIndex] = draggedItem;
        this.$emit('itemsSwapped', [
          { id: targetItem.id, position: this.draggedItemIndex },
          { id: draggedItem.id, position: targetIndex }
        ]);
      }
    },
  },
};
</script>

<style scoped>
.draggable-container {
  display: flex;
  gap: 8px;
}

.draggable-container.vertical {
  flex-direction: column;
}

.draggable-container.horizontal {
  flex-direction: row;
}

.draggable-item {
  padding: 10px;
  margin: 5px;
  border: 1px solid #ccc;
  cursor: grab;
  background-color: #f9f9f9;
  border-radius: 50px;
}

.draggable-item:active {
  cursor: grabbing;
}
</style>
